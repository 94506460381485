/* src/styles/modern-styles.css */

/* =============================================================================
   CSS Variables & Global Styles
   ============================================================================= */
:root {
    /* New Color Scheme */
    --primary-bg: #41444B;
    /* Dark background for header & dropdowns */
    --secondary-bg: #DFD8C8;
    /* Light background for content containers */
    --accent-color: #CABFAB;
    /* Accent (used for profitable bets, checkbox fill, active elements) */
    --text-color: #52575D;
    /* Text color */
    --header-text: #ffffff;
    /* Text on dark backgrounds */
    --border-color: #52575D;
    /* Using text color as border */
    --shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    --rounded: 8px;
    --font-family: 'Roboto', sans-serif;
}

body {
    margin: 0;
    font-family: var(--font-family);
    background: var(--secondary-bg);
    color: var(--text-color);
}

/* =============================================================================
   Custom Checkbox Styles
   ============================================================================= */
.custom-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid var(--primary-bg);
    border-radius: 4px;
    background: var(--secondary-bg);
    cursor: pointer;
    vertical-align: middle;
    position: relative;
    margin-right: 4px;
}

.custom-checkbox:checked {
    background-color: var(--accent-color);
    /* Accent color fill */
    border-color: var(--accent-color);
}

.custom-checkbox:checked::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 5px;
    width: 4px;
    height: 8px;
    border: solid var(--header-text);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* =============================================================================
   Table & Row Colors
   ============================================================================= */
.table-container {
    background-color: var(--secondary-bg) !important;
    padding: 16px;
    border-radius: var(--rounded);
    width: 100%;
    margin: 16px auto;
    box-shadow: var(--shadow);
}

.table-responsive-horizontal {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table {
    width: 100%;
    border-collapse: collapse;
    font-family: var(--font-family);
    font-size: 14px;
}

/* Table header */
.table thead th {
    background-color: var(--primary-bg);
    color: var(--header-text);
    padding: 12px 16px;
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: left;
}

.table th,
.table td {
    padding: 12px 16px;
    border-bottom: 1px solid var(--border-color);
    text-align: left;
}

/* Alternate row backgrounds */
.table>tbody>tr:nth-child(odd) {
    background-color: #fdf8f5;
    /* Very light cream tone */
}

.table>tbody>tr:nth-child(even) {
    background-color: var(--secondary-bg);
}

.table>tbody>tr:hover {
    background-color: var(--accent-color);
}

/* Custom row color classes (with high specificity) */
.table>tbody>tr.table-success {
    background-color: var(--accent-color) !important;
    /* Accent color for profitable bets */
    color: var(--primary-bg) !important;
}

.table>tbody>tr.table-warning {
    background-color: var(--secondary-bg) !important;
    /* Secondary bg for middle bets */
    color: var(--primary-bg) !important;
}

.table>tbody>tr.table-danger {
    background-color: var(--text-color) !important;
    /* Text color (dark grey) for less profitable bets */
    color: var(--header-text) !important;
}

/* =============================================================================
   Buttons, Dropdowns, and Containers
   ============================================================================= */
.modern-button {
    background-color: var(--primary-bg);
    color: var(--header-text);
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: var(--rounded);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modern-button:hover {
    background-color: var(--accent-color);
}

.modern-button.active-tab {
    background-color: var(--accent-color);
    color: #fff;
}

/* NEW: Outlined button style for MLB and NBA buttons.
   This adds a thin border in the accent color. */
.outlined-button {
    border: 1px solid var(--accent-color);
}

.page-container {
    padding: 24px;
    max-width: 1200px;
    margin: 0 auto;
}

.page-header {
    background-color: var(--primary-bg);
    padding: 24px;
    color: var(--header-text);
    text-align: center;
    border-radius: var(--rounded);
    margin-bottom: 24px;
}

/* =============================================================================
   Dropdown Styling
   ============================================================================= */
.dropdown-container {
    text-align: left;
    width: 100%;
    margin-bottom: 16px;
}

.dropdown-button {
    background-color: var(--primary-bg);
    color: var(--header-text);
    padding: 10px 16px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: var(--rounded);
    text-align: left;
}

.dropdown-button:hover {
    background-color: var(--accent-color);
}

.dropdown-content {
    background-color: var(--primary-bg);
    padding: 16px;
    border-radius: var(--rounded);
    font-size: 14px;
    color: var(--header-text);
    text-align: left;
}

/* =============================================================================
   Loading Screen Styling
   ============================================================================= */
.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--primary-bg);
    color: var(--header-text);
    text-align: center;
}

.loading-spinner {
    border: 8px solid rgba(255, 255, 255, 0.2);
    border-top: 8px solid var(--accent-color);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1.5s linear infinite;
    margin-bottom: 16px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* =============================================================================
   Responsive Adjustments
   ============================================================================= */
@media (max-width: 768px) {
    .table {
        font-size: 12px;
    }

    .dropdown-button,
    .dropdown-content {
        font-size: 12px;
    }

    .landing-container p {
        font-size: 14px;
    }

    .modern-button {
        font-size: 14px;
    }
}