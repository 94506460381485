/* src/components/DataTable.css */

.table-container {
    background-color: var(--secondary-bg) !important;
    padding: 10px;
    border-radius: var(--rounded);
    width: 99%;
    max-width: none;
    margin: 0 auto;
}

.table-responsive-horizontal {
    overflow-x: auto;
    white-space: nowrap;
}

.table {
    /* Use auto layout so that columns size themselves according to content */
    table-layout: auto;
    width: auto;
    max-width: 100%;
    border-collapse: collapse;
    font-family: var(--font-family);
    font-size: 10px;
    border-radius: var(--rounded);
    overflow: clip;
}

.table th,
.table td {
    padding: 8px;
    border: 0px solid var(--border-color);
    text-align: left;
    font-family: var(--font-family);
    font-size: 10px;
}

/* Header styling */
.table thead th {
    background-color: var(--primary-bg);
    color: var(--header-text);
    position: sticky;
    top: 0;
    z-index: 1;
}

/* Zebra striping */
.table tbody tr:nth-child(odd) {
    background-color: #fdf8f5;
}

.table tbody tr:nth-child(even) {
    background-color: var(--secondary-bg);
}

/* Make columns with the 'shrink' class only as wide as their content */
.shrink {
    width: 1%;
    white-space: nowrap;
    padding-left: 2px;
    padding-right: 2px;
}

/* Special override for the "o/u" column to further reduce padding */
.ouCell {
    padding-left: 2px !important;
    padding-right: 2px !important;
}

/* Mobile overrides */
@media (max-width: 768px) {
    .table-container {
        padding: 0;
        margin: 0;
        background-color: transparent !important;
    }

    .table th,
    .table td {
        padding: 4px;
        font-size: 10px;
    }
}